import { CloseOutlined, DownOutlined, NotificationFilled, SettingFilled, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Divider, Drawer, Dropdown, Flex, FloatButton, Form, Image, Layout, List, Menu, Radio, Row, Spin, Table, Tabs, Upload, message, Modal, Space} from "antd";
import ImgCrop from 'antd-img-crop';
import { RangePickerProps } from 'antd/es/date-picker';
import { useForm } from 'antd/es/form/Form';
import { RcFile, UploadChangeParam } from "antd/es/upload";
import { UploadFile } from 'antd/lib';
import Pagination, { PaginationProps } from 'antd/lib/pagination';
import copyIcon from 'assets/image/icon-10.svg';
import logo02Icon from 'assets/image/logo-02.png';
import logo01Icon from 'assets/image/logo.ico';
import logoutIcon from 'assets/image/logout.svg';
import mailboxIcon from 'assets/image/mailbox.svg';
import menuIcon from 'assets/image/menu.svg';
import { COOKIE } from 'constants/cookie';
import { RESPONSE_CODE, agenti18n } from 'constants/response';
import { LANG } from 'constants/socket';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'enum/date';
import { CHECKBOX_DISPLAY } from 'enum/state';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { $get } from 'services';
import { $mobile, enumToOptions, langChange } from 'utils/common';
import $package from "../../package.json";
import useAccount from 'hooks/account.hook';
import hintIcon from 'assets/image/icon-12.png';


const topBarHeight = '55px';

export const MainLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Layout style={{ background: "#fff" }}>
      {children}
      <FloatButton.BackTop visibilityHeight={1} />
    </Layout>
  )
};

export const ModalLayout: React.FC<{ children: ReactNode, paddingBottom?: string }> = ({ children, paddingBottom = 0 }) => {
  const { data: $s } = useSite();

  return (
    <>
      <Helmet>
        <link rel="icon" href={$s && $s.Logo1} type="image/x-icon" />
      </Helmet >
      <Flex
        className="login-background-image" justify="center" align="center"
        style={{ width: '100vw', height: '100dvh', paddingBottom }}
      >
        <Card className="login-card">
          <Row
            justify="center" align="middle"
            style={{ position: "absolute", zIndex: 1, top: '-13%', left: '50%', transform: 'translateX(-50%)' }}
          >
            <Col>
              <Image src={logo01Icon} preview={false} width={100} />
            </Col>
          </Row>

          {children}

        </Card>
      </Flex>
    </>
  )
};

export const LayoutNav: React.FC<{
  id?: number;
  account?: string;
  hidden?: boolean;
}> = ({ id, account, hidden }) => {
  const navigate = useNavigate();
  const $n = (url: any) => navigate(url);
  const { data: $s } = useSite();
  const { info, logout, permissions: $p } = useAccount();
  const [currentTime, setCurrentTime] = useState<string>();

  const [page, setPage] = useState<number[]>([1, 10]);
  const { data: SystemAnnouncement, isValidating, mutate } = $get({
    url: 'api/contents/system/publish/list',
    params: {
      Lang: Cookies.get('lang'),
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const [isOpenMenuDrawer, setIsOpenMenuDrawer] = useState(false);
  const [isOpenAnnouncement, setIsOpenAnnouncement] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs().tz($s && $s.TimeZone).format('YYYY-MM-DD HH:mm:ss'));
    }, 1000)
    return () => clearInterval(interval)
  }, []);

  const logo = <Image
    style={{ cursor: 'pointer', width: 'auto' }}
    src={Cookies.get('logo02') || logo02Icon} height={30} preview={false}
    onClick={() => $n('/')}
  />

  // 選單
  const menuItems: any = [
    ($p('0101') || $p('0201')) && {
      key: 'team',
      label: i18n.t('team'),
      children: [
        $p('0101') && { key: 11, label: i18n.t('memberList'), onClick: () => navigate('/team') },
        $p('0201') && { key: 12, label: i18n.t('agentList'), onClick: () => navigate('/team/agent') },
      ],
    },
    {
      key: 'data',
      label: i18n.t('data'),
      children: [
        { key: 21, label: i18n.t('memberData'), onClick: () => navigate('/data') },
        { key: 22, label: i18n.t('dailyReport'), onClick: () => navigate('/data/daily-report') },
        { key: 23, label: i18n.t('costReport'), onClick: () => navigate('/data/cost-report') },
        { key: 24, label: i18n.t('cashbackCommissionReport'), onClick: () => navigate('/data/cashback-report') },
        { key: 25, label: i18n.t('bettingReports'), onClick: () => navigate('/data/bet-report') },
      ],
    },
    ($p('0301')) && {
      label: i18n.t('myWallet'),
      onClick: () => navigate('/wallet')
    },
    (($s.CommissionModule === '1') && $p('0401')) && {
      key: 'advanced',
      label: i18n.t('advanced'),
      children: [
        ($s.CommissionModule === '1') && { key: 31, label: i18n.t('memberRebateSettings'), onClick: () => navigate('/rebate') },
      ],
    },
  ].filter(item => item);

  const { data: LanguageList } = $get({
    url: 'common/language/list',
  });
  const [lang, setLang] = useState(Cookies.get('lang') || document.documentElement.lang);

  const utc = {
    key: 'utc',
    label: (
      <div>
        <div>{`UTC${dayjs().tz($s && $s.TimeZone).format('Z')}`}</div>
        <div>{currentTime}</div>
      </div>
    ),
    className: "time-zone"
  }

  const chatLink = {
    label: <span className="color-01">{i18n.t('chatRoom')}</span>,
    onClick: () => window.open('/#/chat')
  }

  const adminItems: any = [
    {
      key: 'language',
      label:
        <div className="item-style">
          <span>{LANG[lang as keyof typeof LANG]}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children:
        LanguageList?.Data.map((item: any) => {
          return {
            key: item,
            label: LANG[item as keyof typeof LANG],
            onClick: async () => await langChange(item, true)
          }
        }) || []
    },
    {
      key: 'mailbox',
      label: (
        <Badge style={{ background: '#FF4242', boxShadow: 'none' }} offset={[1, 1]}>
          <Image src={mailboxIcon} width={25} height={25} preview={false} />
        </Badge>
      ),
      onClick: () => {
        mutate();
        setIsOpenAnnouncement(true);
      }
    },
    {
      key: 'admin',
      label:
        <Row align="middle">
          <Col>{info.Account}</Col>
        </Row>,
      children: [
        {
          key: 'password',
          label: i18n.t('modifyPassword'),
          onClick: () => navigate('/password')
        },
        {
          key: 'logout',
          label:
            <Row align="middle" gutter={[5, 5]}>
              <Col><Image src={logoutIcon} preview={false} /></Col>
              <Col>{i18n.t('logout')}</Col>
            </Row>,
          onClick: () => {
            logout();
            navigate('/login');
          }
        },
        {
          key: 'ver',
          label:
            <Row justify="center" style={{ borderTop: '1px solid #666666', color: '#FFF', cursor: 'default' }}>
              <Col>{`${i18n.t('version')}(${$package.version})`}</Col>
            </Row>
        },
      ],
    }
  ]

  return (
    <>
      <Helmet>
        <link rel="icon" href={$s && $s.Logo1} type="image/x-icon" />
      </Helmet >
      {/* 手機版 */}
      {$mobile ?
        <Row
          align="middle"
          justify="space-between"
          className="bg-color-02 color-01 menu"
          style={{
            height: topBarHeight
          }}
        >
          <Col span={8}>
            <Row justify={'start'} align={'middle'} style={{ paddingLeft: '32px' }}>
              <Image src={menuIcon} width={25} height={25} preview={false} onClick={() => {
                setIsOpenMenuDrawer(true);
              }} />
            </Row>
          </Col>
          <Col span={8}>
            <Row justify={'center'}>
              {logo}
            </Row>
          </Col>
          <Col span={8}>
            <Row justify={'end'} style={{ paddingRight: '32px' }}>
              <Badge style={{ background: '#FF4242', boxShadow: 'none' }} offset={[1, 1]}>
                <Image src={mailboxIcon} width={25} height={25} preview={false} onClick={() => {
                  mutate();
                  setIsOpenAnnouncement(true);
                }} />
              </Badge>
            </Row>
          </Col>
        </Row>
        :
        // 電腦版
        <Row
          align="middle"
          justify="space-between"
          className="bg-color-02 color-01 menu"
          style={{
            height: topBarHeight
          }}
        >
          <Col span={16}>
            <Menu
              style={{ display: 'flex', alignItems: 'center' }}
              className="bg-color-02 color-01"
              mode="horizontal"
              theme="dark"
              items={[
                {
                  key: '/',
                  label: logo
                },
                ...(menuItems.map((item: any) => ({
                  ...item,
                  label:
                    <div className="item-style" >
                      <span>{item.label}</span>
                      {item.children && <span style={{ marginLeft: 2 }}><DownOutlined /></span>}
                    </div>
                })))
              ]}
            />
          </Col>
          <Col span={8}>
            <Menu
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
              className="bg-color-02 color-01 pr-1"
              mode="horizontal"
              theme="dark"
              items={[
                utc,
                chatLink,
                ...adminItems
              ]}
            />
          </Col>
        </Row>
      }

      {!hidden && <BreadcrumbBar />}

      <MenuDrawer menuItems={menuItems} chatLink={chatLink} open={isOpenMenuDrawer} close={() => setIsOpenMenuDrawer(false)} />
      <Announcement open={isOpenAnnouncement} loading={isValidating}
        close={() => setIsOpenAnnouncement(false)} SystemAnnouncement={SystemAnnouncement} page={page} setPage={setPage} />
    </>
  );
};

// 選單 (左側欄)
export const MenuDrawer: React.FC<{
  menuItems: any;
  chatLink: any;
  open: boolean;
  close: () => void;
}> = ({ menuItems, chatLink, open, close }) => {
  const { info, permissions: $p, logout } = useAccount();
  const navigate = useNavigate();

  const { data: LanguageList } = $get({
    url: 'common/language/list',
  });
  const [lang, setLang] = useState(Cookies.get('lang') || document.documentElement.lang);

  const goto = (path: any) => {
    navigate(path)
    close()
  }

  return (
    <Drawer
      width={$mobile ? '100vw' : '450px'}
      styles={{
        header: {
          height: topBarHeight,
          background: '#1E211D'
        }
      }}
      style={{ background: '#1E211D' }}
      onClose={close}
      closable={false}
      placement={'left'}
      open={open}
      title={
        <Row align="middle" justify="space-between">
          <Col>
            <Image src={Cookies.get('logo02') || logo02Icon} height={24} preview={false} style={{ width: 'auto' }} />
          </Col>
          <Col style={{ cursor: 'pointer' }} onClick={close}>
            <CloseOutlined className="size-16 color-02" />
          </Col>
        </Row >
      }
    >
      {/* 帳號 */}
      <Row
        style={{ padding: '30px 5px 15px 5px', margin: '0px 30px', borderBottom: 'solid 1px #44444499' }}
        align={'middle'}
      >
        <Col>
          <Avatar size={50} style={{ backgroundColor: '#99999944' }} icon={<UserOutlined />} />
        </Col>
        <Col>
          <span className="color-01 size-20 font-w-md ml-1">{info.Account}</span>
        </Col>
      </Row>

      {/* 選單 */}
      <Row
        style={{ padding: '0px 0px', margin: '5px 10px' }}
      >
        <Col span={24}>
          <Menu
            className="bg-color-02 color-03 color-03-hover"
            mode={'inline'}
            items={[
              ...(menuItems.map((item: any) => ({
                ...item,
                label: <span className="color-01">{item.label}</span>
              }))),
              chatLink
            ]}
            defaultOpenKeys={['team', 'data']}
          />
        </Col>
      </Row>

      {/* 功能 */}
      <Row
        style={{ padding: '30px 0px 15px 0px', margin: '0px 5px', borderTop: 'solid 1px #44444499' }}
        justify={'space-around'}
        gutter={[12, 12]}
      >
        <Col>
          <Dropdown placement="topLeft" menu={{
            items: LanguageList?.Data?.map((item: any) => ({
              key: item,
              label: LANG[item as keyof typeof LANG],
              onClick: async () => await langChange(item, true)
            })) || []
          }} >
            <Button ghost className='w-full text-center'>{LANG[lang as keyof typeof LANG]}</Button>
          </Dropdown>
        </Col>
        <Col>
          <Button ghost className='w-full text-center' onClick={() => {
            goto('/password')
          }}>
            {i18n.t('modifyPassword')}
          </Button>
        </Col>
        <Col>
          <Button ghost className='w-full text-center' onClick={() => {
            logout();
            message.success(i18n.t('logoutSuccess'));
            goto('/login');
          }}>
            {i18n.t('logout')}
          </Button>
        </Col>
        <Col span={24} style={{ color: '#999', cursor: 'default', textAlign: 'center' }}>
          {`${i18n.t('version')}(${$package.version})`}
        </Col>
      </Row>

    </Drawer >
  )
}

// 系統公告 (右側欄)
export const Announcement: React.FC<{
  open: boolean;
  close: () => void;
  SystemAnnouncement: {
    Data: any;
    TotalRecord: number;
  };
  loading: any;
  page: number[];
  setPage: any;
}> = ({ open, close, SystemAnnouncement, loading, page, setPage }) => {
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [data, setData] = useState<Contents[]>();
  const [details, setDetails] = useState<Contents>();
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  useEffect(() => {
    if (SystemAnnouncement) {
      setData(SystemAnnouncement.Data);
    }
  }, [SystemAnnouncement])

  const showChildrenDrawer = (key: number) => {
    setChildrenDrawer(true);
    data?.map(item => (
      item.Id === key && setDetails(item)
    ))
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const onClose = () => {
    const isFirstLogin = Cookies.get(COOKIE.FIRST_LOGIN);
    if (!isFirstLogin) {
      Cookies.set(COOKIE.FIRST_LOGIN, 'false', { expires: 1 });
      setIsFirstOpen(false);
    }
    close();
  }

  return (
    <Drawer
      width={$mobile ? '100vw' : '450px'}
      styles={{
        header: {
          height: topBarHeight,
          background: '#1E211D'
        }
      }}
      onClose={onClose}
      closable={false}
      title={
        <Row align="middle" justify="space-between">
          <Col>
            <Row align="middle" gutter={[12, 12]}>
              <Col><Image src={mailboxIcon} preview={false} height={22} /></Col>
              <Col className="size-16 color-02">{i18n.t('systemAnnouncement')}</Col>
            </Row>
          </Col>
          <Col style={{ cursor: 'pointer' }} onClick={onClose}>
            <CloseOutlined className="size-16 color-02" />
          </Col>
        </Row >
      }
      open={
        Cookies.get(COOKIE.FIRST_LOGIN) ? open : isFirstOpen
      }
    >
      <Spin spinning={loading}>
        <List
          style={{ marginBottom: 50 }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item key={item.Id} onClick={() => showChildrenDrawer(item.Id)}>
              <List.Item.Meta
                title={
                  <div className="size-18" style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden', textOverflow: 'ellipsis',
                  }}>
                    {<SettingFilled />} {item.Title}
                  </div>
                }
                description={
                  <div className="size-14" style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden', textOverflow: 'ellipsis'
                  }}>{item.Content.replace(/<\/?[^>]+(>|$)/g, "")}
                  </div>
                }
              />
            </List.Item>
          )}
        />
        {/* 頁碼 */}
        <div style={{ position: 'fixed', bottom: 30, right: 30 }}>
          <LayoutPagination total={SystemAnnouncement ? SystemAnnouncement.TotalRecord : 0}
            setPage={setPage} page={page} hiddenSizeChange={false} />
        </div>

        {/* 內容 */}
        {
          details &&
          <Drawer
            width={$mobile ? '100vw' : '450px'}
            styles={{
              header: {
                height: topBarHeight,
                background: '#1E211D'
              }
            }}
            onClose={onChildrenDrawerClose}
            closable={false}
            title={
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle" gutter={[12, 12]}>
                    <Col><NotificationFilled className="size-18 color-01" style={{ height: '30px' }} /></Col>
                    <Col className="size-16 color-02">{i18n.t('announcementDetail')}</Col>
                  </Row>
                </Col>
                <Col style={{ cursor: 'pointer' }} onClick={onChildrenDrawerClose}>
                  <CloseOutlined className="size-16 color-02" />
                </Col>
              </Row >
            }
            open={childrenDrawer}
          >
            <Row style={{ padding: 20 }} >
              <Col span={24} className="font-w-md color-pass size-18">
                {i18n.t('mainTitle')}：
              </Col>
              <Col span={24} className="size-16" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                {details.Title}
              </Col>

              <Col span={24} className="size-18 font-w-md color-down pt-1">
                {i18n.t('content')}：
              </Col>
              <Col span={24} className="size-16" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                dangerouslySetInnerHTML={{ __html: details.Content }} />
            </Row>
          </Drawer>
        }
      </Spin >
    </Drawer >
  )
}

// 麵包屑
export const BreadcrumbBar: React.FC = () => {

  const location = useLocation();
  const { id, account } = useParams<any>();

  // NOTE: 這邊要module參數化
  const breadcrumbItems = new Map();

  breadcrumbItems.set('/', [
    { title: i18n.t('home') },
  ]);
  breadcrumbItems.set('/team', [
    { title: i18n.t('team') },
    { title: i18n.t('memberList') }
  ]);
  breadcrumbItems.set(`${'/team/member-rebate/'}${id}/${account}`, [
    { title: i18n.t('team') },
    { title: <Link to={'/team'}>{i18n.t('memberList')}</Link> },
    { title: `${account}` }
  ]);
  breadcrumbItems.set(`${'/team/member-provider/'}${id}/${account}`, [
    { title: i18n.t('team') },
    { title: <Link to={'/team'}>{i18n.t('memberList')}</Link> },
    { title: `${account}` }
  ]);
  breadcrumbItems.set('/team/agent', [
    { title: i18n.t('team') },
    { title: i18n.t('agentList') }
  ]);
  breadcrumbItems.set(`${'/team/agent-edit/'}${id}`, [
    { title: i18n.t('team') },
    { title: <Link to={'/team/agent'}>{i18n.t('agentList')}</Link> },
    { title: i18n.t('edit') },
  ]);
  breadcrumbItems.set('/data', [
    { title: i18n.t('data') },
    { title: i18n.t('memberData') }
  ]);
  breadcrumbItems.set('/data/daily-report', [
    { title: i18n.t('data') },
    { title: i18n.t('dailyReport') }
  ]);
  breadcrumbItems.set('/data/cost-report', [
    { title: i18n.t('data') },
    { title: i18n.t('costReport') }
  ]);
  breadcrumbItems.set('/data/cashback-report', [
    { title: i18n.t('data') },
    { title: i18n.t('cashbackCommissionReport') }
  ]);
  breadcrumbItems.set('/data/bet-report', [
    { title: i18n.t('data') },
    { title: i18n.t('bettingReports') }
  ]);
  breadcrumbItems.set(`/data/bet-report/${account}`, [
    { title: i18n.t('data') },
    { title: i18n.t('bettingReports') }
  ]);
  breadcrumbItems.set('/wallet', [
    { title: i18n.t('myWallet') },
    { title: i18n.t('myWallet') }
  ]);
  breadcrumbItems.set('/rebate', [
    { title: i18n.t('advanced') },
    { title: i18n.t('memberRebateSettings') }
  ]);

  return (
    <>
      <Breadcrumb
        style={{ padding: '17px 33px' }}
        separator=">"
        items={breadcrumbItems.get(location.pathname)}
      />
      <Divider style={{ margin: 0 }} />
    </>
  )
}

export const LayoutPagination = ({
  defaultPageSize = 10,
  defaultPageIndex = 1,
  page,
  setPage,
  total,
  hiddenSizeChange = true
}: {
  defaultPageSize?: number;
  defaultPageIndex?: number;
  page?: number[];
  setPage: React.Dispatch<React.SetStateAction<number[]>>;
  total: number;
  hiddenSizeChange?: boolean;
}) => {
  const [current, setCurrent] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);

  const onShowSizeChange: PaginationProps['onShowSizeChange']
    = (current, pageSize) => {
      setPage([current, pageSize]);
      setCurrent(current);
      setCurrentPageSize(pageSize);
    };

  return (
    <Row justify="end" className="mt-1">
      <Pagination
        showSizeChanger={hiddenSizeChange}
        total={total}
        onChange={onShowSizeChange}
        current={page ? page[0] : current}
        pageSize={page ? page[1] : currentPageSize}
        defaultPageSize={defaultPageSize}
        showTotal={(total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`}
      />
    </Row>
  )
}

// 時間區間選擇(AG新日曆)
export const DatePickerCol: React.FC<{
  span?: number | string, // 寬度
  form?: any, name?: any,
  date: any,              // state
  setDate: any,           // state
  initDate?: boolean,     // 重置
  format?: string,        // 格式
  ignoreDateType?: any,   // 不顯示週期
  displayCom?: any,       // 顯示元件
  disabled?: boolean,     // 全禁用
  rangeLimit?: any,       // 搜尋範圍
  defaultDateType?: any,  // 預設
  setFormChanged?: any    // 表單更動
  textAlign?: string,     // 對齊
  components?: any,       // 中間元件
}> = ({
  span,
  form, name,
  date,
  setDate,
  initDate,
  format = 'YYYY-MM-DD HH:mm:ss',
  ignoreDateType,
  displayCom = ['Picker', 'Radio'],
  disabled,
  rangeLimit = DATE_RANGE_LIMIT.all,
  defaultDateType,
  setFormChanged = () => { },
  textAlign,
  components
}) => {

    // 伺服器的今天
    const serverZone = window?.serverZone || "Asia/Taipei";
    const L = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss'))
    const S = dayjs(dayjs().tz(serverZone).format('YYYY-MM-DD HH:mm:ss'))
    const diff = L.diff(S, 'hour');
    const shift = (day: any) => format.includes('HH:mm') ? day.add(diff, 'hour') : day;

    const today = dayjs();
    const yesterday = today.add(-1, 'day').startOf('day');
    const thisWeek = today.add(0, 'week').startOf('day');
    const lastWeek = today.add(-1, 'week').startOf('day');
    const lastMonth = today.add(-1, 'month');

    const showTime = format.includes('HH') && !$mobile

    const [pickerStartOpen, setPickerStartOpen] = useState(false);
    const [pickerEndOpen, setPickerEndOpen] = useState(false);

    // 初始化
    useEffect(() => {
      dateSwitch(defaultDateType || DATE_TYPE.today)
    }, [initDate])

    // Picker快捷
    const presets: { id: DATE_TYPE, label: string, value: Dayjs[] }[] = [
      {
        id: DATE_TYPE.today,
        label: i18n.t('today'),
        value: [shift(today.startOf('day')), shift(today.endOf('day'))]
      },
      {
        id: DATE_TYPE.yesterday,
        label: i18n.t('yesterday'),
        value: [shift(yesterday.startOf('day')), shift(yesterday.endOf('day'))]
      },
      {
        id: DATE_TYPE.thisWeek,
        label: i18n.t('thisWeek'),
        value: [shift(thisWeek.startOf('week')), shift(thisWeek.endOf('week'))]
      },
      {
        id: DATE_TYPE.lastWeek,
        label: i18n.t('lastWeek'),
        value: [shift(lastWeek.startOf('week')), shift(lastWeek.endOf('week'))]
      },
      {
        id: DATE_TYPE.thisMonth,
        label: i18n.t('thisMonth'),
        value: [shift(today.startOf('month')), shift(today.endOf('month'))]
      },
      {
        id: DATE_TYPE.lastMonth,
        label: i18n.t('lastMonth'),
        value: [shift(lastMonth.startOf('month')), shift(lastMonth.endOf('month'))]
      },
    ];

    // Radio快捷
    const dateSwitch = (type: DATE_TYPE) => {
      switch (type) {
        case DATE_TYPE.today:
          setDate([shift(today.startOf('day')).format(format), shift(today.endOf('day')).format(format), type])
          break;
        case DATE_TYPE.yesterday:
          setDate([shift(yesterday.startOf('day')).format(format), shift(yesterday.endOf('day')).format(format), type])
          break;
        case DATE_TYPE.thisWeek:
          setDate([shift(thisWeek.startOf('week')).format(format), shift(thisWeek.endOf('week')).format(format), type])
          break;
        case DATE_TYPE.lastWeek:
          setDate([shift(lastWeek.startOf('week')).format(format), shift(lastWeek.endOf('week')).format(format), type])
          break;
        case DATE_TYPE.thisMonth:
          setDate([shift(today.startOf('month')).format(format), shift(today.endOf('month')).format(format), type])
          break;
        case DATE_TYPE.lastMonth:
          setDate([shift(lastMonth.startOf('month')).format(format), shift(lastMonth.endOf('month')).format(format), type])
          break;
      }
      setFormChanged(true);
      if (form && name) {
        form.setFieldValue(name, date);
        form.validateFields([name]);
      }
    }

    const changeDate = (dateString: any, index: any) => {
      date[index] = dateString || ''
      const final = dayjs(dateString).add((rangeLimit || 31) - 1, 'day').format(showTime ? 'YYYY-MM-DD 23:59:59' : format);
      // 自動區間
      if (index === 0 && dateString) {
        if (
          ((rangeLimit === DATE_RANGE_LIMIT.past7Days || rangeLimit === DATE_RANGE_LIMIT.past31Days) && dayjs(date[1]).isAfter(final, 'day')) ||
          dayjs(date[0]).isAfter(dayjs(date[1]), 'day')
        ) {
          date[1] = final;
          setPickerEndOpen(true);
        }
      }
      setDate([date[0], date[1], null])
      setFormChanged(true);
      if (form && name) {
        form.setFieldValue(name, [date[0], date[1], null]);
        form.validateFields([name]);
      }
    }

    // 去空白
    const initialized = useRef(false)
    useEffect(() => {
      const w: any = window
      w.startTime = '';
      w.endTime = '';

      if (date) {
        w.startTime = date[0];
        w.endTime = date[1];
      }
      if (!initialized.current) {
        initialized.current = true;

        const input = document.getElementsByClassName('ant-picker-input')
        input[0]?.addEventListener('change', function (event: any) {
          if (format.includes('HH:mm:ss')) {
            const dateString = event.target.value.split(' ');
            w.startTime = `${dateString[0]} ${dateString[1]}`;
          } else {
            const dateString = event.target.value;
            w.startTime = `${dateString}`;
          }
          setDate([w.startTime, w.endTime, null]);
        })
        input[1]?.addEventListener('change', function (event: any) {
          if (format.includes('HH:mm:ss')) {
            const dateString = event.target.value.split(' ');
            w.endTime = `${dateString[0]} ${dateString[1]}`;
          } else {
            const dateString = event.target.value;
            w.endTime = `${dateString}`;
          }
          setDate([w.startTime, w.endTime, null]);
        })
      }
    }, [date]);

    const disabledDateStart: RangePickerProps['disabledDate'] = (current) => {
      switch (rangeLimit) {
        case DATE_RANGE_LIMIT.all:
          return false;
        case DATE_RANGE_LIMIT.future:
          return current < today.startOf('day');
        default:
          return current < today.add(-3, 'month').startOf('month');
      }
    };

    const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
      switch (rangeLimit) {
        case DATE_RANGE_LIMIT.past7Days:
          // 沒有HH:mm:ss的格式會多一天不知道為啥
          return current < dayjs(date[0]) || dayjs(date[0]).add(showTime ? 7 : 6, 'day') < current;
        case DATE_RANGE_LIMIT.past31Days:
          return current < dayjs(date[0]) || dayjs(date[0]).add(showTime ? 31 : 30, 'day') < current;
        default:
          return current < dayjs(date[0]);
      }
    };

    return (
      <>
        {displayCom.includes('Picker') && <>
          <Col span={span}>
            <DatePicker
              open={pickerStartOpen}
              onOpenChange={setPickerStartOpen}
              showNow={false}
              style={{ width: '100%' }}
              placeholder={i18n.t('startTime') as string}
              placement={'bottomLeft'}
              format={format}
              showTime={showTime ? {
                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
              } : false}
              value={dayjs(date[0])}
              allowClear={false}
              onChange={(e, d) => changeDate(d, 0)}
              disabled={disabled}
              disabledDate={disabledDateStart}
              presets={
                displayCom.includes('Presets') && presets
                  .filter(item => !ignoreDateType || !ignoreDateType.includes(item.id))
                  .map(item => ({ label: item.label, value: item.value[0] })) as any
              }
            />
          </Col>
          <Col span={span}>
            <DatePicker
              open={pickerEndOpen}
              onOpenChange={setPickerEndOpen}
              showNow={false}
              style={{ width: '100%' }}
              placeholder={i18n.t('endTime') as string}
              placement={'bottomLeft'}
              format={format}
              showTime={showTime ? {
                defaultValue: dayjs('23:59:59', 'HH:mm:ss'),
              } : false}
              value={dayjs(date[1])}
              allowClear={false}
              onChange={(e, d) => changeDate(d, 1)}
              disabled={disabled}
              disabledDate={disabledDateEnd}
              presets={
                displayCom.includes('Presets') && presets
                  .filter(item => !ignoreDateType || !ignoreDateType.includes(item.id))
                  .map(item => ({ label: item.label, value: item.value[1] })) as any
              }
            />
          </Col>
        </>}
        {
          components && components
        }
        {displayCom.includes('Radio') &&
          <Col span={$mobile ? 24 : ''} style={{ textAlign: $mobile ? 'left' : textAlign as any }}>
            <Radio.Group
              style={{ width: '100%' }}
              optionType="button"
              buttonStyle="solid"
              value={date[2]}
              options={enumToOptions(DATE_TYPE).filter(item => !ignoreDateType || !ignoreDateType.includes(item.value))}
              onChange={(e) => dateSwitch(e.target.value)}
              disabled={disabled}
            />
          </Col>}
      </>
    )
}

export const CopyButton: React.FC<{
  text: string;
}> = ({ text }) => {

  //copy text
  const handleCopyAccount = (text: string) => {
    navigator.clipboard.writeText(text);
    message.success(i18n.t('copySuccess'))
  };

  return (
    <Button onClick={() => handleCopyAccount(text)} className="center" size="small" type="link">
      <Image className="center" src={copyIcon} preview={false} />
    </Button>
  )
}

export const ExportExcel = ({ exportExcel }: {
  exportExcel: () => void;
}) => {

  // const exportExcel = () => {
  //   const excel = new Excel();

  //   if (dataSource.length !== 0) {
  //     excel
  //       .addSheet(sheetName)
  //       .addColumns(columns)
  //       .addDataSource(dataSource, {
  //         str2Percent: true
  //       })
  //       .saveAs(`${dayjs().format('YYYYMMDDHHmm')}.xlsx`, "blob")
  //     message.success(i18n.t('downloadSuccess'));
  //   }
  // }

  return (
    <Button className='excel-btn' onClick={exportExcel}>{i18n.t('exportExcel')}</Button>
  )
}

// 代理權限
export const SelectAgentPermission = ({ agent, permissions, setPermissions, confirm }: any) => {

  const { data: $s, isCashVersion: $sc } = useSite();

  useEffect(() => {
    if (agent) {
      updateCheckStateOne();
    }
  }, [agent]);

  const allList = agenti18n($sc, $s);

  const [form] = useForm();

  const [menuCheckState, setMenuCheckState] = useState<any>({});
  const updateCheckState = (record: any, level: any, checked: any, itemKey?: any) => {
    const fieldsValue: any = {};

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    allList.forEach((page: any) => {
      managementSum = 0;

      if (page.MenuName === record.MenuName) {
        let readCount = false;
        menuTotal = menuTotal + page.child.length

        page.child.forEach((item: any, i: number) => {
          // 計算勾選數量
          if (
            (level === 'menu' && checked) ||
            (page.name === record.name && (
              (level === 'management' && checked) ||
              (level === 'operation' && form.getFieldValue(`cb-${item.key}`))
            ))
          ) {
            managementSum++
            menuSum++
            if (i === 0) readCount = true;
          }
          else if (
            page.name !== record.name && (level === 'management' || level === 'operation') && form.getFieldValue(`cb-${item.key}`)
          ) {
            menuSum++
          }
          // 改變勾選狀態
          if ((level === 'management' && page.name === record.name) || level === 'menu') {
            fieldsValue[`cb-${item.key}`] = checked;

            const index = permissions.indexOf(item.key);
            if (!checked && index > -1) permissions.splice(index, 1);
            else if (checked && index === -1) permissions.push(item.key);
          } else if (level === 'operation' && item.key === itemKey) {
            const index = permissions.indexOf(item.key);
            if (!checked && index > -1) permissions.splice(index, 1);
            else if (checked && index === -1) permissions.push(item.key);
          }
          // 查看自動勾起
          if ((level === 'operation' && page.key === record.key) && checked) {
            if (page.child.length === 2 && !fieldsValue[`cb-${page.child[0].key}`] && !readCount) {
              managementSum++
              menuSum++
              readCount = true;
            }
            fieldsValue[`cb-${page.child[0].key}`] = true;

            const index = permissions.indexOf(page.child[0].key);
            if (checked && index === -1) permissions.push(page.child[0].key);
          }
        })

        if (((level === 'management' || level === 'operation') && page.name === record.name) || level === 'menu') {
          if (managementSum === 0) menuCheckState[page.name] = CHECKBOX_DISPLAY.空
          else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.方塊
          else if (managementSum === page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.打勾
        }
      }
    })

    if (menuSum === 0) menuCheckState[record.MenuName] = CHECKBOX_DISPLAY.空
    else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[record.MenuName] = CHECKBOX_DISPLAY.方塊
    else if (menuSum === menuTotal) menuCheckState[record.MenuName] = CHECKBOX_DISPLAY.打勾
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })
    setPermissions(permissions)
  };
  const updateCheckStateOne = () => {
    const fieldsValue: any = {};
    const permissionCode = JSON.parse(agent.Permission);
    const permissions: any = [];

    let menuTotal = 0
    let menuSum = 0
    let managementSum = 0

    let menuName = allList[0].MenuName;

    allList.forEach((page: any, i: number) => {
      managementSum = 0;
      menuTotal = menuTotal + page.child.length;

      // management
      page.child.forEach((item: any) => {
        // 計算勾選數量
        if (permissionCode.includes(item.key)) {
          managementSum++
          menuSum++
          fieldsValue[`cb-${item.key}`] = true;
          permissions.push(item.key);
        }
      })
      if (managementSum === 0) menuCheckState[page.name] = CHECKBOX_DISPLAY.空
      else if (managementSum > 0 && managementSum < page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.方塊
      else if (managementSum === page.child.length) menuCheckState[page.name] = CHECKBOX_DISPLAY.打勾

      // menu
      if ((allList[i + 1]?.MenuName !== menuName) || !allList[i + 1]) {
        if (menuSum === 0) menuCheckState[menuName] = CHECKBOX_DISPLAY.空
        else if (menuSum > 0 && menuSum < menuTotal) menuCheckState[menuName] = CHECKBOX_DISPLAY.方塊
        else if (menuSum === menuTotal) menuCheckState[menuName] = CHECKBOX_DISPLAY.打勾

        menuName = allList[i + 1]?.MenuName;
        menuSum = 0;
        menuTotal = 0;
      }
    })
    form.setFieldsValue({ ...fieldsValue })
    setMenuCheckState({ ...menuCheckState })
    setPermissions(permissions)
  };

  return (
    <Form form={form}>
      <Table
        className="w-full"
        size="middle"
        bordered
        dataSource={allList}
        columns={[
          {
            key: 1,
            title: <><span className="require">*</span>{i18n.t('page')}</>,
            width: '25%',
            onCell: (record, index) => ({ rowSpan: record.rowSpan || 0 }),
            render: (_, record: any) =>
              <Checkbox
                indeterminate={menuCheckState[record.MenuName] === CHECKBOX_DISPLAY.方塊}
                checked={menuCheckState[record.MenuName] === CHECKBOX_DISPLAY.打勾}
                onChange={e => updateCheckState(record, 'menu', e.target.checked)}
                disabled={confirm}
              >{record.MenuName}</Checkbox>,
          },
          {
            key: 2,
            className: 'size-12',
            width: '25%',
            render: (_, record: any) =>
              <Checkbox
                indeterminate={menuCheckState[record.name] === CHECKBOX_DISPLAY.方塊}
                checked={menuCheckState[record.name] === CHECKBOX_DISPLAY.打勾}
                onChange={e => updateCheckState(record, 'management', e.target.checked)}
                disabled={confirm}
              >{record.name || record.ManagementName}</Checkbox>
          },
          {
            key: 3,
            title: i18n.t('permissions'),
            width: '50%',
            render: (_, record) => record.child.map((item: any) =>
              <Form.Item key={item.key} name={`cb-${item.key}`} valuePropName="checked" style={{ display: 'inline-block' }}>
                <Checkbox
                  onChange={e => updateCheckState(record, 'operation', e.target.checked, item.key)}
                  disabled={confirm}
                >{`${item.name}`}</Checkbox>
              </Form.Item>
            )
          },
        ]}
        pagination={false}
      />
    </Form>
  )
}

// 上傳圖片
export const UploadImage = ({
  form, name,
  imageData, setImageData,
  url, disabled,
  crop, cropShape = 'rect',
  maxCount = 1, w = 5, h = 4,
  onRemove, button,
  accept = ".jpg, .png",
  fillColor
}: any) => {

  const [loading, setlLoading] = useState(false);

  const handleFileListChange = async (data: UploadChangeParam<UploadFile<any>>) => {
    const { fileList } = data;
    setImageData(fileList);
    setlLoading(true);
  };

  useEffect(() => {
    if (imageData && imageData[0] && imageData[0].status === 'done') {
      const newFormData = new FormData();

      imageData.forEach((file: any) => {
        newFormData.append('Media', file.originFileObj as RcFile);
      });

      try {
        const upload = fetch(`/api${url}`, {
          headers: {
            "Authorization": Cookies.get(COOKIE.TOKEN) as any
          },
          method: 'POST',
          body: newFormData,
        })
          .then(res => {
            const response: any = res.json();
            if (res.ok) return response;
            else throw response;
          })
          .then(data => {
            if (data.State === 'Success') {
              const ary = data.Data.map((path: any) => ({
                uid: path,
                name: path,
                url: path
              }));
              setImageData(ary);
              setlLoading(false);

              if (form && name) {
                form.setFieldValue(name, url);
                form.validateFields([name]);
              }
            } else throw data;
          })
      } catch (e: any) {
        const msg = RESPONSE_CODE[e.Message as keyof typeof RESPONSE_CODE];
        message.error(msg || i18n.t('processingFailed'));
        setImageData([]);
        setlLoading(false);
      }
    }
  }, [imageData])

  const upload =
    <Upload
      multiple
      accept={accept}
      listType="picture"
      maxCount={maxCount}
      fileList={imageData}
      onChange={handleFileListChange}
      // NOTE: 網址亂填是要修正紅匡 看能不能關閉action
      action={'api/seo/create'}
      showUploadList={{
        showRemoveIcon: false
      }}
      onRemove={onRemove}
    >
      {button ? button : <Button type="primary" disabled={disabled}>{i18n.t('upload')}</Button>}
    </Upload>

  return (
    <Spin spinning={loading}>
      {
        crop
          ?
          <ImgCrop
            cropShape={cropShape}
            fillColor={fillColor}
            aspect={w / h}
            showGrid
            showReset
            resetText={`${i18n.t('reset')}`}
            modalTitle={`${i18n.t('editImage')}`}
          >
            {upload}
          </ImgCrop>
          : (upload)
      }
    </Spin>
  )
}

export const LayoutTabMember: React.FC<any> = ({ activeKey }) => {
  const navigate = useNavigate();
  const { id, account } = useParams();

  const onTabClick = (key: any) => {
    switch (key) {
      case '1':
        navigate(`/team/member-rebate/${id}/${account}`);
        break;
      case '2':
        navigate(`/team/member-provider/${id}/${account}`);
        break;
    }
  }

  const items: any = [
    { key: '1', label: i18n.t('rebateList') },
  ];

  return (
    <Tabs className="color-03" style={{ marginTop: '20px' }} size="small"
      activeKey={activeKey} items={items} onTabClick={onTabClick} />
  );
};

// 詢問視窗
export const InquiryWindow = ({ isOpen, close, msg, action, isLoading }: any) => {
  const handleSubmit = () => {
    action();
    close();
  }

  return (
    <Modal
      open={isOpen}
      onOk={handleSubmit}
      onCancel={close}
      centered
      width={450}
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" key="cancel" onClick={close}>
              {i18n.t('cancel')}
            </Button>
          </Col>
          <Col>
            <Button className="mt-1" key="confirm" type="primary" loading={isLoading} onClick={handleSubmit}>
              {i18n.t('confirm')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{msg || i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}